import React, { useState } from 'react';
import { ChevronLeft, Settings, HelpCircle, Mic, Square, Play, Pause, Flag, Trash2, AlertCircle, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const RecordingPage = () => {
  const navigate = useNavigate();
  const [recordingState, setRecordingState] = useState('idle'); // idle, recording, paused
  const [recordingTime, setRecordingTime] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  
  const getButtonIcon = () => {
    switch(recordingState) {
      case 'recording':
        return <Square className="w-8 h-8 text-white" />;
      case 'paused':
        return <Play className="w-8 h-8 text-white" />;
      default:
        return <Mic className="w-8 h-8 text-white" />;
    }
  };
  
  const getStatusText = () => {
    switch(recordingState) {
      case 'recording':
        return 'Recording... Tap to pause';
      case 'paused':
        return 'Paused... Tap to resume';
      default:
        return 'Tap to start recording';
    }
  };

  return (
    <div className="h-screen flex flex-col bg-gray-50">
      {/* Top Navigation */}
      <nav className="px-4 py-3 bg-white border-b">
        <div className="flex items-center justify-between">
          <button 
            className="p-2"
            onClick={() => navigate(-1)}
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          <h1 className="text-lg font-medium">New Recording</h1>
          <div className="flex items-center gap-2">
            <button className="p-2">
              <HelpCircle className="w-6 h-6" />
            </button>
            <button className="p-2">
              <Settings className="w-6 h-6" />
            </button>
          </div>
        </div>
      </nav>

      {/* Main Recording Area */}
      <div className="flex-1 flex flex-col items-center justify-center p-6 relative">
        {/* Recording Timer */}
        <div className="mb-8 text-center">
          <div className="text-3xl font-medium mb-2">
            {Math.floor(recordingTime / 60)}:{(recordingTime % 60).toString().padStart(2, '0')}
          </div>
          <div className="text-sm text-gray-500">
            Max recording time: 5:00
          </div>
        </div>

        {/* Waveform Visualization */}
        <div className="w-full max-w-md mb-12">
          <div className="bg-white rounded-xl p-4 shadow-sm h-32">
            <div className="w-full h-full flex items-center gap-1">
              {Array.from({ length: 50 }).map((_, i) => (
                <div
                  key={i}
                  className={`flex-1 ${
                    recordingState === 'recording' 
                      ? 'bg-blue-500 animate-pulse' 
                      : 'bg-gray-300'
                  }`}
                  style={{
                    height: recordingState === 'recording' 
                      ? `${Math.random() * 100}%` 
                      : '20%',
                    transition: 'height 0.1s ease'
                  }}
                />
              ))}
            </div>
          </div>
        </div>

        {/* Recording Controls */}
        <div className="flex flex-col items-center gap-4">
          {/* Main Record Button */}
          <button
            onClick={() => {
              setRecordingState(state => {
                switch(state) {
                  case 'idle':
                    return 'recording';
                  case 'recording':
                    return 'paused';
                  case 'paused':
                    return 'recording';
                  default:
                    return 'idle';
                }
              });
            }}
            className={`w-24 h-24 rounded-full flex items-center justify-center transition-all ${
              recordingState === 'recording'
                ? 'bg-red-500 scale-110 animate-pulse'
                : 'bg-blue-500 hover:bg-blue-600'
            }`}
          >
            {getButtonIcon()}
          </button>
          
          {/* Status Text */}
          <div className="text-gray-600">
            {getStatusText()}
          </div>
        </div>

        {/* Recording Markers (Optional) */}
        {recordingState !== 'idle' && (
          <div className="absolute bottom-24 right-6">
            <button className="w-12 h-12 bg-white rounded-full shadow-lg flex items-center justify-center">
              <Flag className="w-5 h-5 text-gray-600" />
            </button>
          </div>
        )}
      </div>

      {/* Bottom Toolbar */}
      {recordingState !== 'idle' && (
        <div className="bg-white border-t p-4">
          <div className="max-w-md mx-auto flex items-center justify-between">
            <button 
              onClick={() => setShowConfirmDialog(true)}
              className="w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center"
            >
              <Trash2 className="w-5 h-5 text-gray-600" />
            </button>
            
            <button
              className={`px-6 py-3 rounded-lg font-medium flex items-center gap-2 ${
                recordingState === 'paused'
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-100 text-gray-400'
              }`}
              disabled={recordingState !== 'paused'}
              onClick={() => navigate('/demo/process')}
            >
              <Check className="w-5 h-5" />
              Next
            </button>
          </div>
        </div>
      )}

      {/* Confirmation Dialog */}
      {showConfirmDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-xl p-6 max-w-sm w-full">
            <div className="flex items-center justify-center mb-4">
              <AlertCircle className="w-12 h-12 text-red-500" />
            </div>
            <h3 className="text-lg font-medium text-center mb-2">
              Are you sure you want to delete the recording?
            </h3>
            <p className="text-gray-500 text-center mb-6">
              This action cannot be undone.
            </p>
            <div className="flex gap-3">
              <button
                onClick={() => setShowConfirmDialog(false)}
                className="flex-1 px-4 py-2 border rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setShowConfirmDialog(false);
                  setRecordingState('idle');
                  setRecordingTime(0);
                }}
                className="flex-1 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecordingPage;
