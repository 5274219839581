import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy, deleteDoc, doc } from 'firebase/firestore';
import { signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import { db, auth } from '../../firebase';
import {
  buttonStyles,
  inputStyles,
  containerStyles,
  tableStyles,
  textStyles,
  formStyles,
  alertStyles,
  layoutStyles,
  loadingStyles
} from '../../styles/templates';

function AdminPage() {
  const [emails, setEmails] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAdmin(!!user);
      setCurrentUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const userCredential = await signInWithEmailAndPassword(auth, e.target.email.value, e.target.password.value);
      setCurrentUser(userCredential.user);
    } catch (error) {
      setError('Login failed. Please check your credentials.');
      console.error('Login failed:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const fetchEmails = async () => {
    if (!currentUser) return;
    
    try {
      const q = query(collection(db, 'betaSignups'), orderBy('timestamp', 'desc'));
      const snapshot = await getDocs(q);
      setEmails(snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));
      setError(''); // Clear any existing errors
    } catch (error) {
      console.error('Error fetching emails:', error);
      setError('Failed to fetch email list: ' + error.message);
    }
  };

  const handleDelete = async (id) => {
    if (!currentUser) {
      setError('You must be logged in to delete entries');
      return;
    }

    try {
      setError(''); // Clear any existing errors
      console.log('Current user:', currentUser.email);
      console.log('Attempting to delete document with ID:', id);
      
      const docRef = doc(db, 'betaSignups', id);
      await deleteDoc(docRef);
      
      console.log('Document successfully deleted');
      await fetchEmails(); // Refresh the list
      setDeleteConfirm(null); // Clear confirmation dialog
    } catch (error) {
      console.error('Error deleting document:', error);
      setError('Failed to delete entry: ' + error.message);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchEmails();
    }
  }, [currentUser]);

  const downloadCSV = () => {
    const csvContent = [
      ['Email', 'Name', 'Timestamp'],
      ...emails.map(signup => [
        signup.email,
        signup.name,
        new Date(signup.timestamp.seconds * 1000).toLocaleString()
      ])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `beta-signups-${new Date().toISOString().split('T')[0]}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  if (loading) {
    return (
      <div className={loadingStyles.container}>
        <div className={loadingStyles.spinner}></div>
      </div>
    );
  }

  if (!currentUser) {
    return (
      <div className={containerStyles.page}>
        <div className={containerStyles.section}>
          <div className="max-w-md w-full mx-auto space-y-8">
            <div>
              <h2 className={textStyles.h1}>Admin Login</h2>
            </div>
            <form className={formStyles.container} onSubmit={handleLogin}>
              {error && (
                <div className={alertStyles.error}>{error}</div>
              )}
              <div className="space-y-px">
                <div>
                  <input
                    name="email"
                    type="email"
                    required
                    className={inputStyles.default}
                    placeholder="Admin Email"
                  />
                </div>
                <div>
                  <input
                    name="password"
                    type="password"
                    required
                    className={inputStyles.default}
                    placeholder="Password"
                  />
                </div>
              </div>

              <div>
                <button type="submit" className={buttonStyles.primary}>
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={containerStyles.page}>
      <div className={containerStyles.section}>
        <div className={layoutStyles.flexBetween + " mb-6"}>
          <div>
            <h1 className={textStyles.h1}>Beta Signups</h1>
            <p className={textStyles.body}>Logged in as: {currentUser.email}</p>
          </div>
          <div className="space-x-4">
            <button onClick={downloadCSV} className={buttonStyles.success}>
              Download CSV
            </button>
            <button onClick={handleLogout} className={buttonStyles.secondary}>
              Logout
            </button>
          </div>
        </div>

        {error && (
          <div className={alertStyles.error}>{error}</div>
        )}

        <div className={containerStyles.card}>
          <table className={tableStyles.container}>
            <thead className={tableStyles.header}>
              <tr>
                <th className={tableStyles.headerCell}>Email</th>
                <th className={tableStyles.headerCell}>Name</th>
                <th className={tableStyles.headerCell}>Signup Date</th>
                <th className={tableStyles.headerCell}>Actions</th>
              </tr>
            </thead>
            <tbody className={tableStyles.row}>
              {emails.map((signup) => (
                <tr key={signup.id}>
                  <td className={tableStyles.cell}>{signup.email}</td>
                  <td className={tableStyles.cell}>{signup.name}</td>
                  <td className={tableStyles.cellMuted}>
                    {new Date(signup.timestamp.seconds * 1000).toLocaleString()}
                  </td>
                  <td className={tableStyles.cell}>
                    {deleteConfirm === signup.id ? (
                      <div className="flex items-center space-x-2">
                        <button
                          onClick={() => handleDelete(signup.id)}
                          className={buttonStyles.danger}
                        >
                          Confirm
                        </button>
                        <button
                          onClick={() => setDeleteConfirm(null)}
                          className={textStyles.body}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={() => setDeleteConfirm(signup.id)}
                        className={buttonStyles.danger}
                      >
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AdminPage;
