import React, { useState } from 'react';
import { ChevronLeft, Settings, Undo, Redo, Play, Pause, Scissors, Trash2, VolumeX, Volume2, Save, Hash, Plus, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const AudioProcessingPage = () => {
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedText, setSelectedText] = useState(null);
  const [tags, setTags] = useState(['daily', 'life', 'productivity']);
  const [suggestedTags, setSuggestedTags] = useState(['happy_feeling', 'inspiration', 'diary', 'vlog']);
  
  const addTag = (tag) => {
    if (!tags.includes(tag)) {
      setTags([...tags, tag]);
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  return (
    <div className="h-screen flex flex-col bg-gray-50">
      {/* Top Navigation */}
      <nav className="px-4 py-3 bg-white border-b flex items-center justify-between">
        <div className="flex items-center gap-4">
          <button className="p-2">
            <ChevronLeft className="w-6 h-6" />
          </button>
          <div className="flex gap-2">
            <button className="p-2 text-gray-500 hover:text-gray-700">
              <Undo className="w-5 h-5" />
            </button>
            <button className="p-2 text-gray-500 hover:text-gray-700">
              <Redo className="w-5 h-5" />
            </button>
          </div>
        </div>
        <div className="flex gap-3">
          <button className="p-2">
            <Settings className="w-6 h-6" />
          </button>
          <button className="px-4 py-2 bg-blue-500 text-white rounded-lg font-medium flex items-center gap-2">
            <Save className="w-4 h-4" />
            Save Draft
          </button>
        </div>
      </nav>

      {/* Main Content */}
      <div className="flex-1 overflow-y-auto">
        <div className="max-w-4xl mx-auto p-4 space-y-6">
          {/* Waveform Section */}
          <div className="bg-white rounded-xl p-4 shadow-sm">
            <div className="flex items-center justify-between mb-3">
              <h2 className="text-lg font-medium">Audio Waveform</h2>
              <button 
                className="p-2 rounded-lg bg-gray-100 hover:bg-gray-200"
                onClick={() => setIsPlaying(!isPlaying)}
              >
                {isPlaying ? 
                  <Pause className="w-5 h-5" /> : 
                  <Play className="w-5 h-5" />
                }
              </button>
            </div>
            <div className="h-24 bg-gray-50 rounded-lg p-2">
              <div className="w-full h-full flex items-center gap-1">
                {Array.from({ length: 100 }).map((_, i) => (
                  <div
                    key={i}
                    className="flex-1 bg-blue-500"
                    style={{
                      height: `${Math.random() * 100}%`,
                      opacity: i >= 30 && i <= 40 ? 1 : 0.5
                    }}
                  />
                ))}
              </div>
            </div>
          </div>

          {/* Transcript Section */}
          <div className="bg-white rounded-xl p-4 shadow-sm">
            <h2 className="text-lg font-medium mb-3 flex justify-between items-center">
              <span>Transcript</span>
              <div className="flex gap-2">
                <button className="p-2 text-gray-500 hover:text-gray-700">
                  <Scissors className="w-5 h-5" />
                </button>
                <button className="p-2 text-gray-500 hover:text-red-500">
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            </h2>
            <div className="p-3 bg-gray-50 rounded-lg min-h-[100px] text-gray-700">
              This is a sample text. 
              <span className="bg-yellow-100">Modified parts</span>  
              <span className="bg-red-100">look like</span> 
              <span className="bg-green-100">this</span>.
            </div>
          </div>

          {/* Emotion, Tone, Speed Controls */}
          <div className="bg-white rounded-xl p-4 shadow-sm">
            <h2 className="text-lg font-medium mb-4">Volume control</h2>
            <div className="space-y-4">
              <div>
                <div className="flex justify-between mb-1">
                  <label className="text-sm font-medium">Emotional intensity</label>
                  <span className="text-sm text-gray-500">75</span>
                </div>
                <input 
                  type="range" 
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                  defaultValue={75}
                />
              </div>
              <div>
                <div className="flex justify-between mb-1">
                  <label className="text-sm font-medium">Tone control</label>
                  <span className="text-sm text-gray-500">60</span>
                </div>
                <input 
                  type="range" 
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                  defaultValue={60}
                />
              </div>
              <div>
                <div className="flex justify-between mb-1">
                  <label className="text-sm font-medium">Play speed</label>
                  <span className="text-sm text-gray-500">1.0x</span>
                </div>
                <input 
                  type="range" 
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                  min="0.5"
                  max="2"
                  step="0.1"
                  defaultValue={1}
                />
              </div>
            </div>
          </div>

          {/* Background Sound */}
          <div className="bg-white rounded-xl p-4 shadow-sm">
            <h2 className="text-lg font-medium mb-4">Add background sound</h2>
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-3">
                <button className="p-3 border rounded-lg text-center hover:bg-gray-50 flex items-center justify-center gap-2">
                  <Volume2 className="w-4 h-4" />
                  Cafe
                </button>
                <button className="p-3 border rounded-lg text-center hover:bg-gray-50 flex items-center justify-center gap-2">
                  <Volume2 className="w-4 h-4" />
                  Nature
                </button>
                <button className="p-3 border rounded-lg text-center hover:bg-gray-50 flex items-center justify-center gap-2">
                  <Volume2 className="w-4 h-4" />
                  City
                </button>
                <button className="p-3 border rounded-lg text-center hover:bg-gray-50 flex items-center justify-center gap-2">
                  <Volume2 className="w-4 h-4" />
                  Library
                </button>
              </div>
              <div>
                <div className="flex justify-between mb-1">
                  <label className="text-sm font-medium">Background sound volume</label>
                  <span className="text-sm text-gray-500">30</span>
                </div>
                <div className="flex items-center gap-3">
                  <VolumeX className="w-4 h-4 text-gray-400" />
                  <input 
                    type="range" 
                    className="flex-1 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                    defaultValue={30}
                  />
                  <Volume2 className="w-4 h-4 text-gray-400" />
                </div>
              </div>
            </div>
          </div>

          {/* Hashtags Section */}
          <div className="bg-white rounded-xl p-4 shadow-sm">
            <h2 className="text-lg font-medium mb-4">Hashtag</h2>
            {/* Current Tags */}
            <div className="flex flex-wrap gap-2 mb-4">
              {tags.map((tag) => (
                <span 
                  key={tag} 
                  className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full flex items-center gap-1 text-sm"
                >
                  <Hash className="w-3 h-3" />
                  {tag}
                  <button 
                    onClick={() => removeTag(tag)}
                    className="ml-1 hover:text-blue-800"
                  >
                    <X className="w-3 h-3" />
                  </button>
                </span>
              ))}
              <button className="px-3 py-1 border border-dashed border-gray-300 rounded-full text-gray-500 hover:border-gray-400 text-sm flex items-center gap-1">
                <Plus className="w-3 h-3" />
                Add tag
              </button>
            </div>
            {/* AI Suggested Tags */}
            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-2">AI Suggested Tags</h3>
              <div className="flex flex-wrap gap-2">
                {suggestedTags.map((tag) => (
                  <button
                    key={tag}
                    onClick={() => addTag(tag)}
                    className="px-3 py-1 bg-gray-50 text-gray-600 rounded-full text-sm hover:bg-gray-100 flex items-center gap-1"
                  >
                    <Plus className="w-3 h-3" />
                    {tag}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Preview Bar */}
      <div className="bg-white border-t p-4">
        <div className="max-w-4xl mx-auto flex items-center justify-between">
          <div className="flex items-center gap-4">
            <button 
              className="w-10 h-10 rounded-full bg-blue-500 flex items-center justify-center text-white"
              onClick={() => setIsPlaying(!isPlaying)}
            >
              {isPlaying ? 
                <Pause className="w-5 h-5" /> : 
                <Play className="w-5 h-5" />
              }
            </button>
            <div className="text-sm">
              <div className="font-medium">Preview</div>
              <div className="text-gray-500">1:23 / 2:45</div>
            </div>
          </div>
          <button className="px-4 py-2 bg-blue-500 text-white rounded-lg"               onClick={() => navigate('/demo/profile')}
          >
            Publish
          </button>
        </div>
      </div>
    </div>
  );
};

export default AudioProcessingPage;