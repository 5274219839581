import React, { useState, useEffect } from 'react';
import { ChevronLeft, Settings, Edit2, MessageCircle, Play, Pause, Heart, MessageSquare, Share2, Home, Compass, Bell, User, Bookmark, Grid, List, X, Plus, ListPlus } from 'lucide-react';
import { InfiniteScroll, BottomNavigation } from '../shared';
import { useNavigate } from 'react-router-dom';

const ProfilePage = () => {
  const navigate = useNavigate();
  // States
  const [activeTab, setActiveTab] = useState('all');
  const [playingAudio, setPlayingAudio] = useState(null);
  const [viewMode, setViewMode] = useState('list');
  const [isFollowing, setIsFollowing] = useState(false);
  const [isOwner] = useState(true);
  const [expandedComments, setExpandedComments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [showPlaylistModal, setShowPlaylistModal] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [userData, setUserData] = useState({
    username: "Sarah Kim",
    bio: "Sharing thoughts on mindfulness and travel. Voice creator & storyteller.",
    followers: 1234,
    following: 890,
    posts: [
      {
        id: 1,
        title: "Morning Meditation Guide",
        duration: "2:15",
        likes: 234,
        comments: 45,
        pinned: true,
        commentList: [
          { id: 1, username: "Commenter1", text: "Really good story!", timestamp: "1 hour ago" },
          { id: 2, username: "Commenter2", text: "I agree.", timestamp: "30 minutes ago" },
        ]
      },
      {
        id: 2,
        title: "Travel Stories: Tokyo",
        duration: "3:45",
        likes: 567,
        comments: 89,
        commentList: [
          { id: 1, username: "Traveler1", text: "I really want to go to Tokyo!", timestamp: "2 hours ago" },
          { id: 2, username: "Traveler2", text: "Thanks for the recommendations.", timestamp: "1 hour ago" },
        ]
      },
    ]
  });

  const loadMorePosts = () => {
    setLoading(true);
    setTimeout(() => {
      const newPosts = [...Array(5)].map((_, i) => ({
        id: userData.posts.length + i + 1,
        title: `Voice Post ${userData.posts.length + i + 1}`,
        duration: "2:30",
        likes: Math.floor(Math.random() * 1000),
        comments: Math.floor(Math.random() * 100),
        commentList: [
          { id: 1, username: "Commenter1", text: "Great story!", timestamp: "1 hour ago" },
          { id: 2, username: "Commenter2", text: "Thank you.", timestamp: "30 min ago" },
        ]
      }));
      setUserData(prev => ({
        ...prev,
        posts: [...prev.posts, ...newPosts]
      }));
      setLoading(false);
      if (userData.posts.length > 30) setHasMore(false);
    }, 1000);
  };

  // Playlist Modal Component
  const PlaylistModal = () => (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50">
      <div className="w-full max-w-md bg-white rounded-xl p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">Add to playlist</h3>
          <button
            onClick={() => setShowPlaylistModal(false)}
            className="p-1 rounded hover:bg-gray-100"
          >
            <X className="w-5 h-5 text-gray-500" />
          </button>
        </div>
        <div className="space-y-2">
          {['My Playlist', 'Favorite Music', 'Inspiring Stories'].map((playlist) => (
            <button
              key={playlist}
              className="w-full p-3 rounded-lg text-left hover:bg-gray-50"
            >
              {playlist}
            </button>
          ))}
          <button className="w-full p-3 rounded-lg text-left flex items-center gap-2 text-blue-500 hover:bg-gray-50">
            <Plus className="w-4 h-4" />
            New Playlist
          </button>
        </div>
      </div>
    </div>
  );

  // Audio Content Card Component
  const AudioCard = ({ post, viewMode }) => (
    <div className={`${viewMode === 'grid'
      ? 'bg-white rounded-xl p-4'
      : 'p-4 bg-white border-b'
      }`}>
      <div className="flex items-start justify-between mb-3">
        <div>
          <div className="font-medium">{post.title}</div>
          <div className="text-sm text-gray-500">{post.duration}</div>
        </div>
        <button
          onClick={() => {
            setSelectedPostId(post.id);
            setShowPlaylistModal(true);
          }}
          className="p-2 rounded-full hover:bg-gray-100"
        >
          <ListPlus className="w-5 h-5 text-gray-500" />
        </button>
      </div>

      <div className="bg-gray-50 rounded-xl p-4 mb-3">
        <div className="flex items-center gap-4">
          <button
            className={`w-12 h-12 rounded-full flex items-center justify-center text-white ${playingAudio === post.id ? 'bg-green-500' : 'bg-blue-500'
              }`}
            onClick={() => setPlayingAudio(playingAudio === post.id ? null : post.id)}
          >
            {playingAudio === post.id ?
              <Pause className="w-6 h-6" /> :
              <Play className="w-6 h-6" />
            }
          </button>
          {viewMode === 'list' && (
            <div className="flex-1">
              <div className="h-8 flex items-center gap-0.5">
                {Array.from({ length: 50 }).map((_, i) => (
                  <div
                    key={i}
                    className={`flex-1 ${playingAudio === post.id ? 'bg-blue-500' : 'bg-gray-300'
                      }`}
                    style={{
                      height: `${Math.random() * 100}%`,
                      opacity: playingAudio === post.id ? 1 : 0.5
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center gap-6">
          <button className="flex items-center gap-2 text-gray-500">
            <Heart className="w-5 h-5" />
            <span className="text-sm">{post.likes}</span>
          </button>
          <button
            onClick={() => setExpandedComments(
              expandedComments === post.id ? null : post.id
            )}
            className="flex items-center gap-2 text-gray-500"
          >
            <MessageSquare className="w-5 h-5" />
            <span className="text-sm">{post.comments}</span>
          </button>
          <button className="flex items-center gap-2 text-gray-500">
            <Share2 className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Comments Section */}
      {expandedComments === post.id && (
        <div className="mt-4 space-y-4">
          {post.commentList.map((comment) => (
            <div key={comment.id} className="flex gap-3">
              <div className="w-8 h-8 rounded-full bg-gray-200 flex-shrink-0" />
              <div>
                <div className="flex items-center gap-2">
                  <span className="font-medium">{comment.username}</span>
                  <span className="text-xs text-gray-500">{comment.timestamp}</span>
                </div>
                <p className="text-sm text-gray-600">{comment.text}</p>
              </div>
            </div>
          ))}

          {/* Comment Input */}
          <div className="flex gap-3 mt-4">
            <div className="w-8 h-8 rounded-full bg-gray-200 flex-shrink-0" />
            <input
              type="text"
              placeholder="Comment here"
              className="flex-1 rounded-full px-4 py-2 text-sm bg-gray-100 placeholder-gray-500"
            />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      {showPlaylistModal && <PlaylistModal />}

      {/* Top Navigation */}
      <nav className="px-4 py-3 bg-white border-b sticky top-0 z-10">
        <div className="flex items-center justify-between">
          <button className="p-2"
            onClick={() => navigate(-1)}>
            <ChevronLeft className="w-6 h-6" />
          </button>
          <div className="flex items-center gap-3">
            {isOwner ? (
              <>
                <button className="p-2">
                  <Edit2 className="w-6 h-6 text-gray-600" />
                </button>
                <button className="p-2">
                  <Settings className="w-6 h-6 text-gray-600" />
                </button>
              </>
            ) : (
              <button className="p-2">
                <MessageCircle className="w-6 h-6 text-gray-600" />
              </button>
            )}
          </div>
        </div>
      </nav>

      {/* Profile Header */}
      <div className="bg-white px-4 py-6">
        <div className="flex flex-col items-center">
          {/* Profile Picture */}
          <div className="w-24 h-24 rounded-full bg-gray-200 mb-4 relative">
            {isOwner && (
              <button className="absolute bottom-0 right-0 w-8 h-8 bg-blue-500 rounded-full flex items-center justify-center text-white border-2 border-white">
                <Edit2 className="w-4 h-4" />
              </button>
            )}
          </div>

          {/* User Info */}
          <h1 className="text-xl font-bold mb-2">{userData.username}</h1>
          <p className="text-gray-600 text-center mb-4 max-w-sm">
            {userData.bio}
          </p>

          {/* Follow Stats */}
          <div className="flex gap-6 mb-4">
            <button className="text-center">
              <div className="font-bold">{userData.followers.toLocaleString()}</div>
              <div className="text-sm text-gray-500">Followers</div>
            </button>
            <button className="text-center">
              <div className="font-bold">{userData.following.toLocaleString()}</div>
              <div className="text-sm text-gray-500">Following</div>
            </button>
          </div>

          {/* Action Button */}
          {!isOwner && (
            <button
              onClick={() => setIsFollowing(!isFollowing)}
              className={`px-8 py-2 rounded-full font-medium ${isFollowing
                ? 'bg-gray-100 text-gray-900'
                : 'bg-blue-500 text-white'
                }`}
            >
              {isFollowing ? 'Following' : 'Follow'}
            </button>
          )}
        </div>
      </div>

      {/* Content Tabs */}
      <div className="bg-white border-t border-b sticky top-14 z-10">
        <div className="flex">
          {['all', 'topics', 'bookmarks'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`flex-1 px-4 py-3 text-sm font-medium relative ${activeTab === tab ? 'text-blue-500' : 'text-gray-500'
                }`}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
              {activeTab === tab && (
                <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500" />
              )}
            </button>
          ))}
        </div>

        {/* View Toggle */}
        <div className="px-4 py-2 border-t flex justify-end">
          <div className="flex gap-2">
            <button
              onClick={() => setViewMode('list')}
              className={`p-2 rounded ${viewMode === 'list' ? 'bg-gray-100' : ''
                }`}
            >
              <List className="w-5 h-5 text-gray-600" />
            </button>
            <button
              onClick={() => setViewMode('grid')}
              className={`p-2 rounded ${viewMode === 'grid' ? 'bg-gray-100' : ''
                }`}
            >
              <Grid className="w-5 h-5 text-gray-600" />
            </button>
          </div>
        </div>
      </div>

      {/* Content Display */}
      <InfiniteScroll
        loadMore={loadMorePosts}
        hasMore={hasMore}
        isLoading={loading}
      >
        <div className="max-w-2xl mx-auto pb-20">
          {/* Pinned Content */}
          {userData.posts.filter(post => post.pinned).map(post => (
            <div key={post.id} className="p-4 bg-white border-b">
              <div className="flex items-center gap-2 text-sm text-gray-500 mb-3">
                <Bookmark className="w-4 h-4" />
                Pinned
              </div>
              <AudioCard post={post} viewMode="list" />
            </div>
          ))}

          {/* Regular Content */}
          <div className={viewMode === 'grid' ? 'grid grid-cols-2 gap-4 p-4' : ''}>
            {userData.posts.filter(post => !post.pinned).map(post => (
              <div key={post.id} className={viewMode === 'grid'
                ? 'bg-white rounded-xl overflow-hidden'
                : ''
              }>
                <AudioCard post={post} viewMode={viewMode} />
              </div>
            ))}
          </div>
        </div>
      </InfiniteScroll>

      <BottomNavigation />
    </div>
  );
};

export default ProfilePage;