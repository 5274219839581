import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Home, Compass, Bell, User } from 'lucide-react';

const BottomNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t py-2 px-6">
      <div className="flex justify-around items-center">
        <button 
          className="p-2"
          onClick={() => navigate('/demo')}
        >
          <Home className={`w-6 h-6 ${isActive('/demo') ? 'text-blue-500' : 'text-gray-500'}`} />
        </button>
        <button 
          className="p-2"
          onClick={() => navigate('/demo/profile')}
          >
          <Compass className={`w-6 h-6 ${isActive('/explore') ? 'text-blue-500' : 'text-gray-500'}`} />
        </button>
        <button 
          className="p-2"
          onClick={() => navigate('/notifications')}
        >
          <Bell className={`w-6 h-6 ${isActive('/demo/notifications') ? 'text-blue-500' : 'text-gray-500'}`} />
        </button>
        <button 
          className="p-2"
          onClick={() => navigate('/demo/profile')}
        >
          <User className={`w-6 h-6 ${isActive('/demo/profile') ? 'text-blue-500' : 'text-gray-500'}`} />
        </button>
      </div>
    </div>
  );
};

export default BottomNavigation;
