// Button styles
export const buttonStyles = {
  primary: "px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2",
  secondary: "px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2",
  danger: "text-red-600 hover:text-red-900 focus:outline-none",
  success: "px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2",
  large: "px-8 py-4 text-lg shadow-lg hover:shadow-xl transition-all",
  icon: "p-2 hover:bg-gray-100 rounded-full",
  iconRound: "w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center",
  floatingAction: "fixed right-6 bottom-20 w-14 h-14 bg-blue-500 rounded-full flex items-center justify-center text-white shadow-lg hover:bg-blue-600 transition-colors"
};

// Input styles
export const inputStyles = {
  default: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm",
  error: "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500",
  comment: "flex-1 rounded-full px-4 py-2 text-sm bg-gray-100 placeholder-gray-500",
};

// Container styles
export const containerStyles = {
  page: "min-h-screen bg-gray-50",
  card: "bg-white shadow rounded-lg overflow-hidden",
  section: "max-w-4xl mx-auto",
  feed: "max-w-2xl mx-auto pb-20",
};

// Table styles
export const tableStyles = {
  container: "min-w-full divide-y divide-gray-200",
  header: "bg-gray-50",
  headerCell: "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider",
  row: "bg-white divide-y divide-gray-200",
  cell: "px-6 py-4 whitespace-nowrap text-sm text-gray-900",
  cellMuted: "px-6 py-4 whitespace-nowrap text-sm text-gray-500",
};

// Text styles
export const textStyles = {
  h1: "text-2xl font-bold text-gray-900",
  h2: "text-xl font-semibold text-gray-900",
  body: "text-sm text-gray-600",
  error: "text-sm text-red-600",
  success: "text-sm text-green-600",
  muted: "text-sm text-gray-500",
};

// Form styles
export const formStyles = {
  container: "space-y-6",
  group: "space-y-1",
  label: "block text-sm font-medium text-gray-700",
};

// Alert styles
export const alertStyles = {
  error: "p-4 m-4 text-center text-red-600 bg-red-50 rounded-lg",
  success: "p-3 bg-green-100 text-green-700 rounded-lg",
  warning: "p-3 bg-yellow-100 text-yellow-700 rounded-lg",
  info: "p-3 bg-blue-100 text-blue-700 rounded-lg",
};

// Layout styles
export const layoutStyles = {
  flexBetween: "flex justify-between items-center",
  flexCenter: "flex items-center justify-center",
  flexStart: "flex items-start",
  grid: "grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3",
};

// Modal styles
export const modalStyles = {
  overlay: "fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50",
  container: "w-full max-w-md bg-white rounded-2xl p-6",
  header: "flex justify-between items-center mb-6",
};

// Navigation styles
export const navStyles = {
  container: "px-4 py-3 bg-white border-b sticky top-0 z-10",
  content: "max-w-2xl mx-auto",
  link: "text-gray-600 hover:text-gray-900",
  activeLink: "text-blue-600 hover:text-blue-700",
  tab: "flex-1 px-4 py-3 text-sm font-medium relative",
  tabActive: "text-blue-500",
  tabInactive: "text-gray-500 hover:text-gray-700",
  tabIndicator: "absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500"
};

// Loading styles
export const loadingStyles = {
  spinner: "animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500",
  container: "flex items-center justify-center min-h-screen",
  loadingText: "mt-2 text-gray-500"
};

// Feed Item styles
export const feedStyles = {
  container: "p-4 bg-white border-b",
  userInfo: "flex items-start justify-between mb-3",
  avatar: "w-10 h-10 rounded-full bg-gray-200",
  audioContainer: "rounded-xl p-4 bg-gray-50",
  audioPlayer: "flex items-center gap-4",
  playButton: "w-12 h-12 rounded-full flex items-center justify-center text-white",
  waveform: "h-8 flex items-center gap-0.5",
  waveformBar: "flex-1",
  interactions: "flex items-center justify-between",
  interactionButton: "flex items-center gap-2 text-gray-500 hover:text-blue-500",
  commentSection: "mt-4 space-y-4",
  commentAvatar: "w-8 h-8 rounded-full bg-gray-200 flex-shrink-0",
};

// Infinite Scroll styles
export const infiniteScrollStyles = {
  loadingContainer: "p-4 text-center",
  endMessage: "p-4 text-center text-gray-500",
};
