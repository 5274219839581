import React, { useState, useCallback } from 'react';
import { X } from 'lucide-react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import {
  buttonStyles,
  inputStyles,
  containerStyles,
  textStyles,
  formStyles,
  alertStyles,
  layoutStyles,
  modalStyles,
  navStyles
} from '../../styles/templates';

// Moved outside to prevent unnecessary re-renders
const SignupModal = ({ onClose, formData, handleInputChange, handleSubmit, status, isSubmitting }) => (
  <div className={modalStyles.overlay}>
    <div className={modalStyles.container}>
      <div className={modalStyles.header}>
        <h3 className={textStyles.h2}>Get Beta Access</h3>
        <button onClick={onClose}>
          <X className="w-6 h-6" />
        </button>
      </div>
      <form onSubmit={handleSubmit} className={formStyles.container}>
        <div className={formStyles.group}>
          <label className={formStyles.label}>Email</label>
          <input 
            type="email" 
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className={inputStyles.default}
            placeholder="your@email.com"
            required
            autoComplete="off"
          />
        </div>
        <div className={formStyles.group}>
          <label className={formStyles.label}>Name</label>
          <input 
            type="text" 
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className={inputStyles.default}
            placeholder="Your name"
            required
            autoComplete="off"
          />
        </div>
        {status === 'success' && (
          <div className={alertStyles.success}>
            Thanks for joining our waitlist! We'll be in touch soon.
          </div>
        )}
        {status === 'error' && (
          <div className={alertStyles.error}>
            Please fill in all fields correctly.
          </div>
        )}
        <button 
          type="submit"
          disabled={isSubmitting}
          className={`${buttonStyles.primary} w-full ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {isSubmitting ? 'Submitting...' : 'Notify Me When Beta Launches'}
        </button>
        <p className={textStyles.body + " text-center"}>
          By signing up, you agree to our Terms and Privacy Policy
        </p>
      </form>
    </div>
  </div>
);

const IntroPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ email: '', name: '' });
  const [status, setStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = useCallback((e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // Direct state update without any conditions
    setFormData(currentData => ({ ...currentData, [name]: value }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus('');

    if (!formData.email || !formData.name) {
      setStatus('error');
      setIsSubmitting(false);
      return;
    }

    try {
      await addDoc(collection(db, 'betaSignups'), {
        email: formData.email,
        name: formData.name,
        timestamp: new Date()
      });
      
      setStatus('success');
      setFormData({ email: '', name: '' });
      
      setTimeout(() => {
        setShowModal(false);
        setStatus('');
      }, 2000);
    } catch (error) {
      console.error('Error adding document: ', error);
      setStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white relative">
      {showModal && (
        <SignupModal 
          onClose={() => setShowModal(false)}
          formData={formData}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          status={status}
          isSubmitting={isSubmitting}
        />
      )}
      
      {/* Navigation */}
      <nav className={navStyles.container}>
        <div className={navStyles.content}>
          <div className={layoutStyles.flexBetween}>
            <div className={textStyles.h1}>SayVerse</div>
            <button 
              onClick={() => setShowModal(true)}
              className={buttonStyles.primary}
            >
              Join Waitlist
            </button>
          </div>
        </div>
      </nav>

      {/* Background SVG */}
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <img src="/resources/sayverse-wave-bg.svg" alt="Background" className="w-full h-full object-cover" />
      </div>
      
      {/* Hero Section */}
      <section className="pt-32 pb-20 px-6 min-h-screen flex items-center">
        <div className="max-w-4xl mx-auto text-center">
          <div className="inline-block px-4 py-2 bg-blue-100 rounded-full text-blue-700 font-medium mb-6">
            Coming Soon
          </div>
          <h1 className="text-5xl font-bold mb-6 leading-tight">
            The Future of Voice-Based Social Media
          </h1>
          <p className="text-xl text-gray-600 mb-12 max-w-2xl mx-auto">
            We're working hard to bring you a revolutionary platform where voice creates deeper connections. Sign up now to be notified when our beta launches.
          </p>
          <button 
            onClick={() => setShowModal(true)}
            className={buttonStyles.primary + " " + buttonStyles.large}
          >
            Join the Waitlist
          </button>
          <p className={textStyles.body + " mt-6"}>
            Be among the first to experience SayVerse
          </p>
        </div>
      </section>



      {/* Footer */}
      <footer className="py-8 px-6 text-center text-gray-600">
        <div className="max-w-6xl mx-auto">
          © 2024 SayVerse. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default IntroPage;
