import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ProfilePage from './components/profile/ProfilePage';
import HomePage from './components/home/HomePage';
import RecordingPage from './components/recording/RecordingPage';
import AudioProcessingPage from './components/processing/AudioProcessingPage';
import IntroPage from './components/intro/IntroPage';
import AdminPage from './components/admin/AdminPage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/intro" replace />} />
        <Route path="/intro" element={<IntroPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/demo">
          <Route path="" element={<HomePage />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="record" element={<RecordingPage />} />
          <Route path="process" element={<AudioProcessingPage />} />
        </Route>
      </Routes>
    </Router> 
  );
};

export default App;
