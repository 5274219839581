import React, { useCallback, useEffect } from 'react';
import { loadingStyles, infiniteScrollStyles } from '../../styles/templates';

const InfiniteScroll = ({ 
  children, 
  loadMore, 
  hasMore, 
  isLoading,
  threshold = 100,
  loadingComponent = (
    <div className={infiniteScrollStyles.loadingContainer}>
      <div className={loadingStyles.spinner}></div>
      <div className={loadingStyles.loadingText}>Loading more posts...</div>
    </div>
  ),
  endComponent = (
    <div className={infiniteScrollStyles.endMessage}>
      You've reached the end of the feed
    </div>
  )
}) => {
  const checkScrollPosition = useCallback(() => {
    if (!hasMore || isLoading) return;

    const scrollPosition = window.innerHeight + window.scrollY;
    const scrollThreshold = document.documentElement.scrollHeight - threshold;

    if (scrollPosition >= scrollThreshold) {
      loadMore();
    }
  }, [hasMore, isLoading, loadMore, threshold]);

  // Check scroll position when component mounts or when content changes
  useEffect(() => {
    // If content doesn't fill the viewport, load more
    if (document.documentElement.scrollHeight <= window.innerHeight) {
      if (hasMore && !isLoading) {
        loadMore();
      }
    }
  }, [hasMore, isLoading, loadMore, children]);

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', checkScrollPosition);
    // Also check position after a small delay to handle dynamic content
    const timeoutId = setTimeout(checkScrollPosition, 100);
    
    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
      clearTimeout(timeoutId);
    };
  }, [checkScrollPosition]);

  // Add resize event listener to handle window resizing
  useEffect(() => {
    window.addEventListener('resize', checkScrollPosition);
    return () => window.removeEventListener('resize', checkScrollPosition);
  }, [checkScrollPosition]);

  return (
    <div className="relative">
      {children}
      {isLoading && loadingComponent}
      {!hasMore && !isLoading && endComponent}
    </div>
  );
};

export default InfiniteScroll;
