import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Search, User, Heart, MessageSquare, Share2, Play, Pause, Mic, MoreVertical, ListPlus, X, ChevronDown, ChevronUp, Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { InfiniteScroll, BottomNavigation } from '../shared';
import {
  buttonStyles,
  containerStyles,
  modalStyles,
  navStyles,
  feedStyles,
  textStyles,
  inputStyles,
  alertStyles
} from '../../styles/templates';

const HomePage = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('following');
  const [playingAudio, setPlayingAudio] = useState(null);
  const [expandedComments, setExpandedComments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [showPlaylistModal, setShowPlaylistModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [feedItems, setFeedItems] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const lastIdRef = useRef(0); // Keep track of the last used ID

  // Initial feed load
  useEffect(() => {
    loadMoreItems();
    setInitialLoad(false);
  }, []);

  const loadMoreItems = useCallback(async () => {
    if (loading || (!hasMore && !initialLoad)) return;

    setLoading(true);
    setError(null);

    try {
      // Simulate API call with timeout
      await new Promise(resolve => setTimeout(resolve, 1000));

      const newItems = [...Array(5)].map((_, i) => {
        const newId = lastIdRef.current + i + 1;
        return {
          id: newId,
          username: `User ${newId}`,
          title: `Voice Post #${newId}`,
          timestamp: "just now",
          likes: Math.floor(Math.random() * 1000),
          comments: Math.floor(Math.random() * 100),
          duration: "2:30",
          commentList: [
            { id: 1, username: "Abby Talbot", text: "Inspired!", timestamp: "an hour ago" },
            { id: 2, username: "Brad Blunt", text: "Thank you for sharing.", timestamp: "30 mins ago" },
          ]
        };
      });

      // Update the last used ID
      lastIdRef.current = lastIdRef.current + 5;

      setFeedItems(prev => [...prev, ...newItems]);
      
      // Set hasMore to false after reaching 30 items
      if (feedItems.length + newItems.length >= 30) {
        setHasMore(false);
      }
    } catch (err) {
      setError('Failed to load more items. Please try again.');
      console.error('Error loading more items:', err);
    } finally {
      setLoading(false);
    }
  }, [feedItems.length, loading, hasMore, initialLoad]);

  // PlaylistModal Component
  const PlaylistModal = () => (
    <div className={modalStyles.overlay}>
      <div className={modalStyles.container}>
        <div className={modalStyles.header}>
          <h3 className={textStyles.h2}>Add to playlist</h3>
          <button onClick={() => setShowPlaylistModal(false)}>
            <X className="w-5 h-5" />
          </button>
        </div>
        <div className="space-y-2">
          {['My playlist', 'Favorite music', 'Inspiring stories'].map((playlist) => (
            <button
              key={playlist}
              className="w-full p-3 rounded-lg text-left hover:bg-gray-50"
            >
              {playlist}
            </button>
          ))}
          <button className="w-full p-3 rounded-lg text-left flex items-center gap-2 text-blue-500 hover:bg-gray-50">
            <Plus className="w-4 h-4" />
            Create a new playlist
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className={containerStyles.page}>
      {showPlaylistModal && <PlaylistModal />}

      {/* Top Navigation */}
      <nav className={navStyles.container}>
        <div className={navStyles.content}>
          <div className="flex items-center justify-between">
            <h1 className={textStyles.h1}>SayVerse</h1>
            <div className="flex items-center gap-3">
              <button className={buttonStyles.icon}>
                <Search className="w-6 h-6 text-gray-600" />
              </button>
              <button 
                onClick={() => navigate('/demo/profile')}
                className={buttonStyles.iconRound}
              >
                <User className="w-5 h-5 text-gray-600" />
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Tab Navigation */}
      <div className="bg-white border-b sticky top-14 z-10">
        <div className="max-w-2xl mx-auto flex">
          {['following', 'trending', 'for you'].map((tab) => (
            <button
              key={tab}
              className={`${navStyles.tab} ${
                activeTab === tab ? navStyles.tabActive : navStyles.tabInactive
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
              {activeTab === tab && (
                <div className={navStyles.tabIndicator} />
              )}
            </button>
          ))}
        </div>
      </div>

      {/* Feed Content */}
      <InfiniteScroll
        loadMore={loadMoreItems}
        hasMore={hasMore}
        isLoading={loading}
      >
        <div className={containerStyles.feed}>
          {error && (
            <div className={alertStyles.error}>
              {error}
              <button
                onClick={() => {
                  setError(null);
                  loadMoreItems();
                }}
                className="ml-2 text-blue-500 hover:underline"
              >
                Retry
              </button>
            </div>
          )}
          
          {feedItems.map((item) => (
            <div key={item.id} className={feedStyles.container}>
              {/* User Info */}
              <div className={feedStyles.userInfo}>
                <div className="flex items-center gap-3">
                  <div className={feedStyles.avatar} />
                  <div>
                    <div className="font-medium">{item.username}</div>
                    <div className={textStyles.muted}>{item.timestamp}</div>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <button 
                    onClick={() => {
                      setSelectedItemId(item.id);
                      setShowPlaylistModal(true);
                    }}
                    className={buttonStyles.icon}
                  >
                    <ListPlus className="w-5 h-5 text-gray-500" />
                  </button>
                  <button className={buttonStyles.icon}>
                    <MoreVertical className="w-5 h-5 text-gray-500" />
                  </button>
                </div>
              </div>

              {/* Audio Content */}
              <div className="mb-3">
                <div className="mb-2">{item.title}</div>
                <div className={feedStyles.audioContainer}>
                  <div className={feedStyles.audioPlayer}>
                    <button
                      className={`${feedStyles.playButton} ${
                        playingAudio === item.id ? 'bg-green-500' : 'bg-blue-500'
                      }`}
                      onClick={() => setPlayingAudio(playingAudio === item.id ? null : item.id)}
                    >
                      {playingAudio === item.id ? 
                        <Pause className="w-6 h-6" /> : 
                        <Play className="w-6 h-6" />
                      }
                    </button>
                    <div className="flex-1">
                      <div className={feedStyles.waveform}>
                        {Array.from({ length: 50 }).map((_, i) => (
                          <div
                            key={i}
                            className={`${feedStyles.waveformBar} ${
                              playingAudio === item.id ? 'bg-blue-500' : 'bg-gray-300'
                            }`}
                            style={{
                              height: `${Math.random() * 100}%`,
                              opacity: playingAudio === item.id ? 1 : 0.5
                            }}
                          />
                        ))}
                      </div>
                      <div className="flex justify-between text-sm text-gray-500 mt-1">
                        <span>{playingAudio === item.id ? "0:42" : "0:00"}</span>
                        <span>{item.duration}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Interactions */}
              <div className={feedStyles.interactions}>
                <div className="flex items-center gap-6">
                  <button className={feedStyles.interactionButton}>
                    <Heart className="w-5 h-5" />
                    <span className="text-sm">{item.likes}</span>
                  </button>
                  <button 
                    onClick={() => setExpandedComments(
                      expandedComments === item.id ? null : item.id
                    )}
                    className={feedStyles.interactionButton}
                  >
                    <MessageSquare className="w-5 h-5" />
                    <span className="text-sm">{item.comments}</span>
                  </button>
                  <button className={feedStyles.interactionButton}>
                    <Share2 className="w-5 h-5" />
                  </button>
                </div>
                <button
                  onClick={() => setExpandedComments(
                    expandedComments === item.id ? null : item.id
                  )}
                >
                  {expandedComments === item.id ? 
                    <ChevronUp className="w-5 h-5 text-gray-500" /> : 
                    <ChevronDown className="w-5 h-5 text-gray-500" />
                  }
                </button>
              </div>

              {/* Comments Section */}
              {expandedComments === item.id && (
                <div className={feedStyles.commentSection}>
                  {item.commentList.map((comment) => (
                    <div key={comment.id} className="flex gap-3">
                      <div className={feedStyles.commentAvatar} />
                      <div>
                        <div className="flex items-center gap-2">
                          <span className="font-medium">{comment.username}</span>
                          <span className={textStyles.muted}>{comment.timestamp}</span>
                        </div>
                        <p className={textStyles.body}>{comment.text}</p>
                      </div>
                    </div>
                  ))}
                  
                  {/* Comment Input */}
                  <div className="flex gap-3 mt-4">
                    <div className={feedStyles.commentAvatar} />
                    <input 
                      type="text" 
                      placeholder="Add a comment..."
                      className={inputStyles.comment}
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </InfiniteScroll>

      {/* Floating Action Button */}
      <button 
        onClick={() => navigate('/demo/record')}
        className={buttonStyles.floatingAction}
      >
        <Mic className="w-6 h-6" />
      </button>
      <BottomNavigation />
    </div>
  );
};

export default HomePage;
