import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBGyP6HSyppGOzJrGBumE4YoiWu1NM_55A",
    authDomain: "sayverse-276e5.firebaseapp.com",
    projectId: "sayverse-276e5",
    storageBucket: "sayverse-276e5.firebasestorage.app",
    messagingSenderId: "1035544181323",
    appId: "1:1035544181323:web:07c1ead3d206c3a32e6bce",
    measurementId: "G-NLXZNJK9HX"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
